import '@fontsource/open-sans/300.css';
import '@fontsource/open-sans/400.css';
import '@fontsource/open-sans/600.css';
import '@fontsource/open-sans/700.css';
import '@fontsource/open-sans/300-italic.css';
import '@fontsource/open-sans/400-italic.css';
import '@fontsource/open-sans/600-italic.css';
import '@fontsource/open-sans/700-italic.css';

const initTrustPilotWidgets = () => {
	const trustPilotWidgets = document.querySelectorAll('.js-trustpilot-widget');

	if (trustPilotWidgets.length > 0 && window.Trustpilot) {
		trustPilotWidgets.forEach((trustbox) => {
			window.Trustpilot.loadFromElement(trustbox);
		});
	}
};

export const onRouteUpdate = () => {
	initTrustPilotWidgets();
};

export const onInitialClientRender = () => {
	initTrustPilotWidgets();
};
